var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("heads"),
      _vm._m(0),
      _c("div", { staticClass: "ai" }, [
        _c("div", { staticClass: "ai-article" }, [
          _c("div", { staticClass: "bunner-imgA" }),
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.titleA))])
        ])
      ]),
      _c("div", { staticClass: "ai1" }, [
        _c("div", { staticClass: "ai1-title" }, [_vm._v("建设内容")]),
        _c(
          "div",
          { staticClass: "ai1-article" },
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", [
                    _c("img", {
                      staticClass: "ai1-Img",
                      attrs: { src: _vm.aiImgB }
                    }),
                    _c("div", { staticClass: "ai1-details" }, [
                      _vm._v(_vm._s(_vm.titleB))
                    ])
                  ])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", [
                    _c("img", {
                      staticClass: "ai1-Img",
                      attrs: { src: _vm.aiImgC }
                    }),
                    _c("div", { staticClass: "ai1-details" }, [
                      _vm._v(_vm._s(_vm.titleC))
                    ])
                  ])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", [
                    _c("img", {
                      staticClass: "ai1-Img",
                      attrs: { src: _vm.aiImgD }
                    }),
                    _c("div", { staticClass: "ai1-details" }, [
                      _vm._v(_vm._s(_vm.titleD))
                    ])
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "ai2" }, [
        _c("div", { staticClass: "ai2-title" }, [_vm._v("项目展示")]),
        _c(
          "div",
          { staticClass: "ai2-article" },
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("div", [
                    _c("img", {
                      staticClass: "ai2-Img",
                      attrs: { src: _vm.aiImgE }
                    })
                  ])
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("div", [
                    _c("img", {
                      staticClass: "ai2-Img",
                      attrs: { src: _vm.aiImgF }
                    })
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("foots")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "https://www.aihaedu.cn", target: "_blank" } },
      [_c("div", { staticClass: "ai-banner-src" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }