<template>
  <div>
    <heads />
    <a href="https://www.aihaedu.cn"
      target="_blank">
      <div class="ai-banner-src" />
    </a>
    <div class="ai">
      <div class="ai-article">
        <div class="bunner-imgA" />
        <div class="title">{{titleA}}</div>
      </div>
    </div>
    <div class="ai1">
      <div class="ai1-title">建设内容</div>
      <div class="ai1-article">
        <el-row>
          <el-col :span="8">
            <div>
              <img :src="aiImgB"
                class="ai1-Img">
              <div class="ai1-details">{{titleB}}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div>
              <img :src="aiImgC"
                class="ai1-Img">
              <div class="ai1-details">{{titleC}}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div>
              <img :src="aiImgD"
                class="ai1-Img">
              <div class="ai1-details">{{titleD}}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="ai2">
      <div class="ai2-title">项目展示</div>
      <div class="ai2-article">
        <el-row>
          <el-col :span="12">
            <div>
              <img :src="aiImgE"
                class="ai2-Img">
            </div>
          </el-col>
          <el-col :span="12">
            <div>
              <img :src="aiImgF"
                class="ai2-Img">
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <foots />
    <!-- <contant /> -->
  </div>
</template>
<script>
import heads from './common/head';
import foots from './common/foot';
// import contant from './common/contant';
import aiImgB from '../assets/img/assessment/ai_imgB.png';
import aiImgC from '../assets/img/assessment/ai_imgC.png';
import aiImgD from '../assets/img/assessment/ai_imgD.png';
import aiImgE from '../assets/img/assessment/ai_imgE.png';
import aiImgF from '../assets/img/assessment/ai_imgF.png';

export default {
  components: {
    heads,
    foots,
    // contant,
  },
  data() {
    return {
      aiImgB,
      aiImgC,
      aiImgD,
      aiImgE,
      aiImgF,
      titleA: '人工智能教育服务项目以国家、河南省中小学人工智能教育相关建设意见为指导，以人工智能课程教育教学的需求为导向，为全省中小学人工智能课程体系建设与开发、智能学习支持环境和智能教育教学资源建设，以及中小学人工智能教育的统筹发展提供服务和基础业务支撑',
      titleB: '通过打造线上线下联动的服务体系，形成一个链接各方、服务各方的人工智能教育服务集散中心，提供人工智能教育资源、产品、服务等',
      titleC: '建设1个推动全省中小学人工智能教育发展的综合管理与服务平台；打造全国首家集人工智能教育骨干教师培训、人工智能科普研学体验、人工智能教育成果展示等的科普研学教育展示平台',
      titleD: '打造河南省人工智能教育的课程资源链接枢纽、产品链接枢纽、服务链接枢纽、专家链接枢纽，链接政府、学校、企业、专家、教师、机构、学生等相关服务群体，实现多方融合发展',
    };
  },
  methods: {
    goListen() {
      this.$router.push('hearing');
    },
  },
};
</script>

<style scope lang="less">
.ai-banner-src {
  background: url(../assets/img/assessment/ai_bunner.jpg) center;
  margin-top: 143px;
  text-align: center;
  width: 100%;
  height: 540px;
}
.ai {
  width: 100%;
  background: rgba(250, 250, 250, 1);
  text-align: center;
  .ai-article {
    margin: 0 auto;
    width: 1200px;
    padding-top: 84px;
    padding-bottom: 84px;
    .bunner-imgA {
      background: url(../assets/img/assessment/ai_imgA.png) center;
      text-align: center;
      width: 100%;
      height: 500px;
    }
    .title {
      text-align: left;
      font-size: 18px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      margin-top: 40px;
    }
  }
}
.ai1 {
  width: 100%;
  height: 600px;
  margin-top: 100px;
  margin-bottom: 68px;
  text-align: center;
  .ai1-title {
    font-size: 48px;
    color: #14349b;
    font-weight: 600;
  }
  .ai1-article {
    margin: 64px auto;
    width: 1200px;
    .ai1-Img {
      width: 300px;
      height: 300px;
    }
    .ai1-details {
      text-align: left;
      margin: 0 auto;
      margin-top: 22px;
      width: 347px;
      font-size: 18px;
      color: rgba(89, 89, 89, 1);
    }
  }
}
.ai2 {
  width: 100%;
  height: 600px;
  margin-bottom: 161px;
  text-align: center;
  background: rgba(250, 250, 250, 1);
  .ai2-title {
    font-size: 48px;
    color: #14349b;
    font-weight: 600;
    padding-top: 100px;
  }
  .ai2-article {
    margin: 64px auto;
    width: 1200px;
    .ai2-Img {
      width: 560px;
      height: 360px;
    }
    .ai2-details {
      text-align: left;
      margin: 0 auto;
      margin-top: 22px;
      width: 347px;
      font-size: 18px;
      color: rgba(89, 89, 89, 1);
    }
  }
}
</style>
